import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import { motion } from 'framer-motion';

// GQL Fragments
import { QueryFragments } from "../GraphQL/queryFragments" //eslint-disable-line

// Hooks, Querys & State
import { useAppState } from "../state/appState"

// Components
import { PageMeta } from '../components/pageMeta';
import { BlogCard } from "../components/ui"

const Pager = ({ 
  previousPagePath,
  nextPagePath,
  pageNumber,
  humanPageNumber,
  numberOfPages,
  className }) => {
  return (
    <div className={`${className} flex flex-wrap justify-center items-center`}>
      <span>
        <Link
          className={`${!previousPagePath && 'opacity-25 pointer-events-none'}`}
          to={previousPagePath && previousPagePath}
        >
          Newer
        </Link>
      </span>

      <span className="mx-4">
        Page {humanPageNumber} of {numberOfPages}
      </span>
      <span>
        <Link
          className={`${!nextPagePath && 'opacity-25 pointer-events-none'}`}
          to={nextPagePath && nextPagePath}
        >
          Older
        </Link>
      </span>
    </div>
  );
}

const aniVars = {
  hidden: {
    opacity: 0,
    scale: 0.4,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 2,
      type: 'ease-in-out',
    },
  },
};

const BlogPortal = ({ data: { posts }, pageContext }) => {
  const {
    setGlobalTheme,
    toggleHideHeader,
    toggleHideFooter,
    toggleHeaderUnderline,
  } = useAppState();

  useEffect(() => {
    toggleHideFooter(false);
    toggleHideHeader(false);
    setGlobalTheme('pink');
    toggleHeaderUnderline(true);
  }, [toggleHideFooter, toggleHideHeader, setGlobalTheme, toggleHeaderUnderline]);

  return (
    <>
      <PageMeta
        metaDescription="Keep up to date with our latest news, project updates and inspiration."
        metaTitle="Urban Journal"
      />
      <div className="grid grid-cols-14 py-24 lg:py-48">
        <motion.div className="col-start-2 col-end-14 text-center" variants={aniVars}>
          <h1 className="text-5xl font-bold lg:text-8xl 3xl:text-10xl uppercase mb-16 lg:mb-48 2xl:py-20">
            Urban Journal
          </h1>
        </motion.div>
        {posts.edges && (
          <>
            <div className="col-start-2 col-end-14 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-y-16 lg:gap-x-8 lg:gap-y-20">
              {posts.edges.map((item) => {
                return <BlogCard item={item} />;
              })}
              <Pager className="hidden col-span-1 lg:col-span-2" {...pageContext} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BlogPortal

export const pageQuery = graphql`
  query blogPortalQuery($skip: Int!, $limit: Int!) {
    posts: allSanityBlogPost(
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          date(formatString: "DD MMM YYYY")
          introText
          main {
            title
            slug {
              current
            }
          }
          portalImage {
            image {
              ...ImageWithPreview
            }
          }
          category {
            main {
              title
            }
          }
          meta {
            metaTitle
            metaKeywords
            metaDescription
          }
        }
      }
    }
  }
`
